import React, { useState, useEffect } from "react"
import { Box, Flex, Text } from '@sqymagma/elements'
import ModDashedLine from "./ModDashedLine"
import LethalityLoader from "../../Loaders/LethalityLoeader"
import IconSystem from "../../IconSystem"

export default ({fecha, numPaises = 8}) => {

    const [topLethality, setTopLethality] = useState(null)
    useEffect(() => {
    fetch(`https://covid19.secuoyas.io/api/v1/mod/eu?fecha=${fecha}`)
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
            const countriesData = resultData ? resultData.paises : null;
            if ( countriesData ) {
                const lethalityByCountries = countriesData && countriesData.map(country => {
                    return { 
                        lethality: (country.casosFallecidos / country.casosConfirmados * 100).toFixed(1),
                        iconName: country.codigoIsoLugar.charAt(0) + country.codigoIsoLugar.slice(1).toLowerCase(),
                        nombreLugar: country.nombreLugar.split("_").join(" ")
                    }
            })
            setTopLethality(lethalityByCountries && lethalityByCountries.sort((a, b) => b.lethality - a.lethality).splice(0, numPaises))
            }
        }) 
    }, [fecha, numPaises])

    return (
        <Box my="m" width={1}>
            {topLethality 
                ? (
                <Box>
                    <Text textStyle="l">Tasa de letalidad en Europa</Text>
                    <ModDashedLine/>
                    <Flex flexWrap="wrap" justifyContent="flex-start" mt="m" ml="-8px" mr="-8px">
                        { topLethality.map((el, idx) => (
                            <Box width={topLethality.length < 8 ? 1/(topLethality.length*.9) : 1/4} key={idx} mb="s" px="s" minWidth="5rem">
                                <Box width="2rem">
                                    <IconSystem name={`flag${el.iconName}`} width="100%"/>
                                </Box>
                                <Text as="p" textStyle="s" color="brand05">{el.nombreLugar}</Text>
                                <Text as="p" textStyle="l" color="brand02">{el.lethality + "%"}</Text>
                            </Box>
                        ))}
                    </Flex>
                </Box>
                )
            : (<Box width={1}>
                <LethalityLoader/>
            </Box>)}
        </Box>
    )
}
