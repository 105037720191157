import React from "react"
import ContentLoader from "react-content-loader" 

const LethalityLoader = () => (
  <ContentLoader 
    speed={2}
    width={560}
    height={250}
    viewBox="0 0 560 250"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="3" y="97" rx="3" ry="3" width="88" height="6" /> 
    <rect x="3" y="111" rx="3" ry="3" width="52" height="6" /> 
    <rect x="4" y="15" rx="3" ry="3" width="296" height="10" /> 
    <rect x="0" y="40" rx="3" ry="3" width="560" height="3" /> 
    <rect x="4" y="64" rx="0" ry="0" width="40" height="25" /> 
    <rect x="130" y="98" rx="3" ry="3" width="88" height="6" /> 
    <rect x="130" y="112" rx="3" ry="3" width="52" height="6" /> 
    <rect x="131" y="65" rx="0" ry="0" width="40" height="25" /> 
    <rect x="258" y="98" rx="3" ry="3" width="88" height="6" /> 
    <rect x="258" y="112" rx="3" ry="3" width="52" height="6" /> 
    <rect x="259" y="65" rx="0" ry="0" width="40" height="25" /> 
    <rect x="381" y="98" rx="3" ry="3" width="88" height="6" /> 
    <rect x="381" y="112" rx="3" ry="3" width="52" height="6" /> 
    <rect x="382" y="65" rx="0" ry="0" width="40" height="25" /> 
    <rect x="3" y="185" rx="3" ry="3" width="88" height="6" /> 
    <rect x="3" y="199" rx="3" ry="3" width="52" height="6" /> 
    <rect x="4" y="152" rx="0" ry="0" width="40" height="25" /> 
    <rect x="130" y="186" rx="3" ry="3" width="88" height="6" /> 
    <rect x="130" y="200" rx="3" ry="3" width="52" height="6" /> 
    <rect x="131" y="153" rx="0" ry="0" width="40" height="25" /> 
    <rect x="258" y="186" rx="3" ry="3" width="88" height="6" /> 
    <rect x="258" y="200" rx="3" ry="3" width="52" height="6" /> 
    <rect x="259" y="153" rx="0" ry="0" width="40" height="25" /> 
    <rect x="381" y="186" rx="3" ry="3" width="88" height="6" /> 
    <rect x="381" y="200" rx="3" ry="3" width="52" height="6" /> 
    <rect x="382" y="153" rx="0" ry="0" width="40" height="25" />
  </ContentLoader>
)

export default LethalityLoader